import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 登录
export async function expLogin(params) {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/login`, "post_json", params);
}

// 退出登录
export async function expLoginOut() {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/logout`, "get", {});
}

// 首次登录完善信息
/**
 * @param params {{userid,password,repeatpassword,email}}
 */
export async function addUserInfo(params) {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/perfectInformation`, "post_json", params);
}

// 重置密码
/**
 * @param params {{account,schoolid,email}}
 */
export async function resetPassword(params) {
    return request_async(API_URL_EXP_CONSUMER + `/api/sendResetPassEmail`, "post_json", params);
}

// 发送绑定新邮箱验证码
export async function sendBindEmail(email) {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/sendEmail?email=${email}`, "get", {});
}

// 修改用户信息
/**
 *
 * @param params {{oldpassword,newpassword,code,email}}
 * @returns {Promise<unknown>}
 */
export async function editUserInfo(params) {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/modifyPersonalInformation`, "post_json", params);
}

// 通过账户获取教师列表
/**
 *
 * @param params {{account:String}}
 * @returns {Promise<Promise<unknown> | Promise<unknown>>}
 */
export async function getTeacherListByAccount(params) {
    return request_async(API_URL_EXP_CONSUMER + `/consumer/ums/getTeachers`, "post_json", params);
}