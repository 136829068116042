import store from '@/store/index'
import {LoginModel} from "@/model/exp/LoginModel";

// 路由model
class RouterModel {
    // 路由跳转
    static routerPush($this, path, name, info, index,breadList) {
        console.log(path)
        this.setCurrentNavInfo(path, name, info, index,breadList)
        $this.$router.push(path)
    }

    // 设置当前路由信息
    static setCurrentNavInfo(path, name, info, index,breadList) {
        let currentNavInfo = {
            path,
            name,
            info,
            index,
            breadList
        }
        sessionStorage.setItem("currentNavInfo", JSON.stringify(currentNavInfo))
        // 存入store
        store.commit('SET_CURRENT_NAV', currentNavInfo)
    }

    // 页面强制刷新时调用
    static actionWhenPageRefresh() {
        // 从SessionStorage恢复路由信息
        let currentNavInfo = sessionStorage.getItem("currentNavInfo");
        if (currentNavInfo) {
            currentNavInfo = JSON.parse(currentNavInfo);
            this.setCurrentNavInfo(
                currentNavInfo.path,
                currentNavInfo.name,
                currentNavInfo.info,
                currentNavInfo.index,
                currentNavInfo.breadList
            )
        }
        // 从SessionStorage恢复用户信息
        let userInfoSession = sessionStorage.getItem(LoginModel.userInfoKey)
        if (userInfoSession) { // 存在用户信息
            let userInfo = JSON.parse(userInfoSession)
            // 存入Store
            store.commit("SET_USER", userInfo)
        } else { // 不存在用户信息
            // 跳转到登录页
            window.location.href = LoginModel.getLoginUrl()
        }
    }

    // 登录时调用
    static actionWhenLogin() {
        sessionStorage.removeItem('currentNavInfo')
    }

    // 设置导航栏标题
    static setNavPageTitle(title) {
        let titleE = document.querySelector("#top-info-container .page-name")
        titleE.innerHTML = title
    }
}

export {RouterModel}
