// 常用model
class CommonModel {

    // 对获取到的对象列表进行格式化-Mybatis
    static generateListMybatis(data, page, limit) {
        /**
         * 数据格式
         * {
         *   count:总数量
         *   data:数据
         * }
         * page 从1开始
         */
            // 计算分页数据
        const totalNumber = data.count;// 总元素数量
        const totalPage = Math.ceil(totalNumber / limit);// 总页数
        // 返回[数据,分页对象]
        return [data.data, {
            totalNumber,
            totalPage,
            page,
            limit
        }];
    }

    // 对获取到的对象列表进行格式化-Mongo
    static generateListMongo(data) {
        let list = data.content;
        // 生成分页数据
        delete data.content;
        let page_temp = data;
        page_temp.number += 1;
        // 生成列表
        return [list, page_temp];
    }

    // 对获取到的对象列表进行格式化-MybatisPlus
    static generateListMybatisPlus(data) {
        /**
         * 数据格式
         * {
         *   count:总数量
         *   data:数据
         * }
         * page 从1开始
         */
        // 返回[数据,分页对象]
        return [data.records, {
            totalElements: data.total,
            totalPage: data.pages,
            number: data.current,
            size: data.size
        }];
    }

    // 格式化列表筛选的选项
    static generateListFilterOptions(lName, vName, data, needAll) {
        let options = [];// [{label:"","value":{}]
        let optionsObject = {};// {"value":"label"}
        if (needAll) {// 是否需要全部这个选项 全部的value是空字符串
            options.push({
                label: '全部',
                value: ''
            })
            optionsObject[''] = '全部';
        }
        data.forEach(li => {
            options.push({
                label: li[lName],
                value: li[vName]
            })
            optionsObject[li[vName]] = li[lName];
        });
        return [options, optionsObject];
    }

    // element table的排序
    static elementTableSort(sort, querySort) {
        if (sort.column.sortable === 'custom') {// 只处理自定义排序
            let order = sort.order
            let prop = sort.prop
            // 转换成数组
            let querySortArr = querySort ? querySort.split(";") : []
            let querySortObj = {}
            querySortArr.forEach(li => {
                let querySortObjArr = li.split(",")
                querySortObj[querySortObjArr[0]] = querySortObjArr[1]
            })
            if (order !== null) {// 改变或新增其他排序 todo 现在是可以同时存在多个排序 可以改为同时只有一个排序
                order = order.replace("ending", "")
                if (querySortObj.hasOwnProperty(prop)) {// 改变
                    querySortObj[prop] = order
                } else {// 新增
                    querySortObj[prop] = order
                }
            } else {// 删除排序
                delete querySortObj[prop]
            }
            // 转换成 id,desc;time,asc的字符串
            querySort = ""
            for (let i in querySortObj) {
                if (querySortObj.hasOwnProperty(i)) {
                    if (querySort) {
                        querySort += `;${i},${querySortObj[i]}`
                    } else {
                        querySort += `${i},${querySortObj[i]}`
                    }
                }
            }

        }
        return querySort
    }
}

export {CommonModel}
