/**
 * 登录model
 */
import {
    expLogin,
    addUserInfo,
    resetPassword,
    sendBindEmail,
    editUserInfo,
    getTeacherListByAccount,
    expLoginOut
} from "@/api/exp/LoginApi";
import store from '@/store/index'
import {RouterModel} from "@/model/RouterModel";
import {LOGIN_URL_SESSION_KEY} from "@/model/ConfigModel";
import {use} from "element-ui/src/locale";
import {CommonModel} from "@/model/CommonModel";
import {msg_err} from "@/utils/ele_component";
import {arrToLVArr, getQuery} from "@/utils/common";


class LoginModel {
    static tokenKey = "expToken"
    static userInfoKey = "expUserInfo"

    // 获取存储的token
    static getToken() {
        return sessionStorage.getItem(this.tokenKey)
    }

    // 清除存储的token
    static removeToken() {
        sessionStorage.removeItem(this.tokenKey)
    }

    // 通过账户获取教师列表
    static async getTeacherListByAccount(account, schoolid, schoolname) {
        let [result] = await getTeacherListByAccount({
            account, schoolid, schoolname
        })
        if (result.code === 20000) {
            let data = result.data
            if (data.hasOwnProperty("teacherList")) {
                let teacherList = data.teacherList
                if (teacherList.length === 0) {
                    msg_err("服务器错误，获取教师列表失败!")
                    return
                }
                return CommonModel.generateListFilterOptions("name", "id", teacherList, false)[0]
            } else {
                return false
            }
        } else {

        }
    }

    // exp登录
    static async expLogin(username, password, byType, schoolValue, teacheraccount, teacherid) {
        let data = ""
        if (byType === "schoolName") {
            [data] = await expLogin({
                account: username, password, schoolname: schoolValue, teacheraccount, teacherid
            })
        }
        if (byType === 'schoolId') {
            [data] = await expLogin({
                account: username, password, schoolid: schoolValue, teacheraccount, teacherid
            })
        }
        if (data.code === 20000) {
            let resultData = data.data
            // 判断是不是超级管理员登录
            if (resultData.hasOwnProperty("asSuperAdminLogin")) {
                return {
                    asSuperAdminLogin: true
                }
            }
            // 判断是否是一个学校存在多个学生学号的登录，返回教师列表
            if (resultData.hasOwnProperty("teacherList")) {
                let teacherList = resultData["teacherList"]
                if (teacherList.length > 0) {
                    let teacherListArr = CommonModel.generateListFilterOptions("name", "id", teacherList, false)[0]
                    return {
                        teacherList: teacherListArr
                    }
                } else {
                    msg_err("服务器错误")
                    return false
                }
            }
            // 教师和学生登录
            // 加入学校id
            resultData.schoolId = resultData.schoolid
            if (resultData.schoolname.indexOf('解放军') !== -1) {
                resultData.schoolname = "国防科技大学"
            }
            // 保存用户信息到Store
            store.commit("SET_USER", resultData)
            // 保存用户信息到SessionStorage
            let cookieUserInfo = JSON.parse(JSON.stringify(resultData))
            delete cookieUserInfo.token
            sessionStorage.setItem(this.userInfoKey, JSON.stringify(cookieUserInfo))
            // 保存token到SessionStorage
            sessionStorage.setItem(this.tokenKey, resultData.token)
            // 调用Router相关
            RouterModel.actionWhenLogin()
            // 保存登录地址
            sessionStorage.setItem(LOGIN_URL_SESSION_KEY, "/login?schoolId=" + resultData.schoolid)
            return resultData
        } else {
            return false
        }
    }

    // 首次登录完善信息
    static async addUserInfo(userId, password, email) {
        let [data] = await addUserInfo({
            userid: userId, password, repeatpassword: password, email
        })
        if (data.code === 20000) {
            if (email) { // 更改邮箱
                store.commit("SET_USER_EMAIL", email)
                this.updateSessionUserInfo({email: email})
            }
            return true
        } else {
            return false
        }
    }

    // 重置密码
    static async resetPassword(account, byType, schoolValue, email, teacherid) {
        let data = ""
        if (byType === "schoolName") {
            [data] = await resetPassword({
                account, schoolname: schoolValue, email, teacherid
            })
        }
        if (byType === "schoolId") {
            [data] = await resetPassword({
                account, schoolid: schoolValue, email, teacherid
            })
        }

        if (data.code === 20000) {
            // 判断是否是一个学校存在多个相同学号学生时，返回教师列表让其选择
            let resultData = data.data
            if (resultData) {
                if (resultData.hasOwnProperty("teacherList")) {
                    let teacherList = resultData["teacherList"]
                    if (teacherList.length > 0) {
                        let teacherListArr = CommonModel.generateListFilterOptions("name", "id", teacherList, false)[0]
                        return {
                            teacherList: teacherListArr
                        }
                    } else {
                        msg_err("服务器错误")
                        return false
                    }
                }
            }
            return true
        } else {
            return false
        }
    }

    // exp-退出登录
    static async loginOut() {
        let [result] = await expLoginOut()
        if (result.code === 20000) {
            this.loginOutWithFront()
        } else {

        }
    }

    // 退出登录前端操作
    static loginOutWithFront() {
        if (getQuery("schoolId")) {// 如果路由中有学校id则跳转到学校的登录页
            window.location.href = LoginModel.getLoginUrl() + "?schoolId=" + getQuery("schoolId") + "&returnUrl=" + escape(window.location.href)
        } else {
            window.location.href = LoginModel.getLoginUrl()
        }
        sessionStorage.clear()
    }

    // 获取登录链接
    static getLoginUrl() {
        let sessionUrl = sessionStorage.getItem(LOGIN_URL_SESSION_KEY)
        if (sessionUrl) {
            return sessionUrl
        } else {
            return "/login"
        }
    }


    // 修改个人信息-修改密码
    static async editUserInfo(param) {
        let [data] = await editUserInfo(param)
        if (data.code === 20000) {
            if (param.hasOwnProperty("email")) { // 更改邮箱
                store.commit("SET_USER_EMAIL", param.email)
                this.updateSessionUserInfo({email: param.email})
            }
            return true
        } else {
            return false
        }
    }

    // 修改个人信息-发送绑定邮箱验证码
    static async sendBindEmail(email) {
        let [data] = await sendBindEmail(email)
        if (data.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 更新session的userInfo
    /**
     *
     * @param updateInfo 更新信息
     */
    static updateSessionUserInfo(updateInfo) {
        let userInfo = sessionStorage.getItem(this.userInfoKey);
        if (userInfo) {
            userInfo = JSON.parse(userInfo)
            userInfo = Object.assign(userInfo, updateInfo)
            sessionStorage.setItem(this.userInfoKey, JSON.stringify(userInfo))
        }
    }

}

export {LoginModel}
