<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {WebAnalyticsModel} from "@/model/erp/WebAnalyticsModel";

export default {
  name: 'App',
  components: {

  },
  mounted() {
    // 网站统计
    // WebAnalyticsModel.init()
  }
}
</script>

<style>

</style>
