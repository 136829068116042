/**
 * 路由钩子
 */

import router from './index'
import {RouterModel} from "@/model/RouterModel";
import {EnumsModel} from "@/model/EnumsModel";
import {arrHasString, StringHasArrItem} from "@/utils/common";

router.beforeEach((to, from, next) => {
    // 过滤-to
    if (!StringHasArrItem(
        ["/login", "/dashboard/v1/index","/dashboard/v1/class","/dashboard/v2/index","/dashboard/v2/class","/dashboard/v2/chart","/xc"],
        to.path) && EnumsModel.webSchool.routeList.indexOf(to.path) === -1) { // 此列表内不判断
        // 路由改变时
        RouterModel.actionWhenPageRefresh();
    }
    // 如果是同页面跳转 就刷新 避免缓存
    if (to.path === from.path) {
        // todo 更优方法
        if (to.path !== "/" && EnumsModel.webSchool.routeList.indexOf(to.path) === -1) {
            window.location.href = to.fullPath
        }
    }
    next()
})
