import axios from 'axios'
import {msg_err} from "./ele_component";
import {LoginModel} from '@/model/exp/LoginModel'


const serviceAsync = axios.create({
    timeout: 600000 // request timeout
});

// 请求拦截
serviceAsync.interceptors.request.use(config => {
    // 配置Token验证方式
    if (LoginModel.getToken()) {
        config.headers['Authorization'] = "Bearer " + LoginModel.getToken();
    } else {
        config.headers['Authorization'] = "Bearer null";
    }
    return config
}, error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error)
});

// 响应拦截
serviceAsync.interceptors.response.use(
    response => {
        const res = response.data;
        // 返回码不为000000
        if (res.code !== "000000" && res.code !== 20000) {
            // todo 这儿统一信息框显示接口返回的错误信息
            if (res.msg === '你无权访问此链接') {
                msg_err("登录已失效,请重新登录!");
            } else {
                msg_err(res.msg);
            }

            return Promise.reject(res);
        } else {
            return response;
        }
    },
    // HTTP状态码不为200
    error => {
        // 错误返回
        console.log('err' + error);
        // 请求超时
        if (("" + error).indexOf('timeout') !== -1) {
            msg_err("请求超时,请重试!");
            return Promise.reject(error)
        }
        let msg = error.response.data.msg;
        // 如果错误标志码是401 表示token已过期
        if (error.response.data.status === 401) {
            if(sessionStorage.getItem("expTokenExpire")!=="true"){
                sessionStorage.setItem("expTokenExpire", "true")
                let msg = "登录已失效,请重新登录";
                msg_err(msg)
                setTimeout(() => {
                    LoginModel.loginOutWithFront()
                }, 500)
            }
        } else {
            if (msg === '你无权访问此链接') {
                msg_err("登录已失效,请重新登录!");
            } else {
                msg_err(msg);
            }
        }
        return Promise.reject(error)
    });

/**
 * 封装的async await 用的request
 * edgar 2020.11.15
 * 返回([response.data,response])
 */

export function request_async(url, method, data) {
    return new Promise(function (resolve, reject) {
        if (url && method) {
            switch (method) {
                case "get":
                    serviceAsync({
                        url: url,
                        method: 'get',
                        params: data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "post":
                    serviceAsync({
                        url: url,
                        method: 'post',
                        headers: {'Content-Type': 'multipart/form-data'},
                        data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "post_body":// 以request payload传输
                    serviceAsync({
                        headers: {'Content-Type': 'text/plain'},
                        url: url,
                        method: 'post',
                        data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "post_json":// 以request payload传输
                    serviceAsync({
                        headers: {'Content-Type': 'application/json'},
                        url: url,
                        method: 'post',
                        data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "put":
                    serviceAsync({
                        url: url,
                        method: 'put',
                        data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "put_body":// 以request payload传输
                    serviceAsync({
                        headers: {'Content-Type': 'text/plain'},
                        url: url,
                        method: 'put',
                        data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
                case "delete":
                    serviceAsync({
                        url: url,
                        method: 'delete',
                        params: data
                    }).then(response => {
                        resolve([response["data"], response])
                    }).catch(error => {
                        reject(error);
                    });
                    break;
            }
        } else {
            reject(url + " 请求参数错误!");
        }
    });
}

export default serviceAsync;
