import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        currentNav: {},
        showNav:true,
    },
    mutations: {
        // 设置用户
        SET_USER: (state, user) => {
            state.user = user
        },
        // 设置用户邮箱
        SET_USER_EMAIL: (state, email) => {
            state.user.email = email
        },
        // 设置当前导航信息
        SET_CURRENT_NAV: (state, currentNav) => {
            state.currentNav = currentNav
        },
        // 设置是否显示导航栏
        SET_SHOW_NAV:(state,showNav)=>{
            state.showNav = showNav
        }
    },
    actions: {
        // // 用户登录
        // Login({commit}, info) {
        //     commit('SET_TOKEN', info.token);
        //     commit('SET_USER', info.user);
        // },
    }
})